import React, {FC, useEffect, useState} from "react";
import s from "./Payment.module.css"

import {NavLink, useNavigate} from "react-router-dom";

import Layout from "../../components/Layout";
import Icon from "../../components/Icon";

import 'moment/locale/ru';
import Player from 'react-lottie-player';
import animationData from './animation.json';
import CheckboxInput from "../../components/ModalFormItem/CheckBox";
import {getReviews} from "../../api/restoran";



export const Paysucess: FC = () => {
    const navigate = useNavigate();
    const [rating, setRating] = useState(0); // Хранение рейтинга
    const [comment, setComment] = useState(""); // Хранение комментария
    const [contactMe, setContactMe] = useState(false); // Флаг для "Связаться со мной"
    const [phone, setPhone] = useState(""); // Номер телефона
    const [email, setEmail] = useState(""); // Email
    const [table,setTable] = useState<string>("")
    useEffect(() => {
        const savedData = localStorage.getItem('tableId');
        localStorage.removeItem('fastOrderId')

        if (savedData) {
            setTable(JSON.parse(savedData));
        }
    }, []);
    const handleRatingChange = (value: number) => {
        setRating(value);
    };

    const handleSubmit = async () => {

        try {
            await getReviews({
                "count": rating,
                "comment": comment,
                "callback": contactMe,
                "phoneNumber": contactMe && phone ? phone : undefined,
                "email": contactMe && email ? email : undefined,
                "tableId": table
            })

            navigate("/")
        }catch (e) {
            console.log(e)
        }

        // Добавьте здесь отправку данных на сервер
    };
    return (
        <Layout>
            <div className={s.wrapper}>
            <NavLink  to={"/"}>
                <div   className={s.main}>
                        <Icon.Main />
                </div>
            </NavLink>

                <NavLink  to={"/menupage"} className={s.link}>
                    <div   className={s.wrapMain}>
                            <Icon.Menu />
                        <div className={s.text}>Смотреть меню</div>
                    </div>
                </NavLink>
            </div>

            <div className={s.sucess}>
                <div className={s.sucessTitle}>Счет оплачен</div>
                <div className={s.animation}>
                    <Player
                        loop
                        play
                        animationData={animationData}
                        style={{ width: 300, height: 300 }}
                    />

                </div>
            </div>
            <div className={s.feedback}>
                <div className={s.feedbackTitle}>Оцените обслуживание</div>
                <div className={s.stars}>
                    {[1, 2, 3, 4, 5].map((star) => (
                        <span
                            key={star}
                            className={s.star + (rating >= star ? ` ${s.selected}` : "")}
                            onClick={() => handleRatingChange(star)}
                        >
              ★
            </span>
                    ))}
                </div>

                {!!rating && <>

                    <div className={s.wrapComment}>
                <textarea
                    className={s.comment}
                    placeholder="Ваш комментарий"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
                    </div>
                    <label className={s.contact}>
                        <CheckboxInput
                            name={`check`}
                            label={`Связаться со мной`}
                            onChange={(name,e) => setContactMe(e)}
                            checked={contactMe}
                        />
                    </label>
                </>}
                {contactMe && (
                    <div className={s.contactDetails}>
                        <input
                            className={s.input}
                            type="text"
                            placeholder="Номер телефона"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <input
                            className={s.input}
                            type="email"
                            placeholder="E-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                )}

                {!!rating &&
                    <div className={s.wrapperBut}>

                <button className={s.submit} onClick={handleSubmit}>
                    Отправить
                </button>
                    </div>
                }
            </div>
        </Layout>
    )
}