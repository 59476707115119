import s from "./Age18.module.css"
import {useSetLocationData} from "../../../hooks/locationData";

const Age18 =() =>{

    const setData = useSetLocationData();

    const clickData= (id:number) => {
        const currentTime = Date.now();
        const limit = 24 * 60 * 60 * 1000;

        if(id === 1){
            localStorage.setItem("age18Data", String(currentTime + limit));
            setData(["age18",''])
        } else {
            setData(["age18",''])
        }
    }

    return <div className={s.wrapper}>
            <div className={s.ask}>Вам есть 18 лет?</div>
              <div className={s.wrapButs}>
            <button className={s.yes} onClick={()=>clickData(1)} >Да</button>
            <button className={s.no} onClick={()=>clickData(2)}>Нет</button>
        </div>
    </div>
}

export default Age18