import React, {memo, useEffect, useState} from "react";
import clsx from "clsx";
import s from "./TabsCat.module.css";
import Icon from "../Icon";
import {useGetLocationData, useSetLocationData} from "../../hooks/locationData";
import {IS_NOT_AGE_18} from "../../utils/common";


interface Tabs {
    activeDrink: number,
    setActiveDrink: React.Dispatch<React.SetStateAction<number>>,

}

const TabsCat = memo((props: Tabs) => {
    const { activeDrink, setActiveDrink } = props;

    const setData = useSetLocationData();
    const { age18 } = useGetLocationData();

    const isActiveModal = () => {
        if (IS_NOT_AGE_18 === "true") {
            const age18Data = Number(localStorage.getItem("age18Data"));
            const currentTime = Date.now();

            if (isNaN(age18Data) || age18Data <= currentTime) {
                setData(["age18", true]);
                setActiveDrink(1);
            } else {
                setActiveDrink(2);
            }
        } else {
            setActiveDrink(2);
        }
    };

    return (
        <div className={s.wrapperActiveButtons}>
            <div className={clsx(s.eat, s.but, { [s.activeEat]: activeDrink === 1 })}
                 onClick={() => setActiveDrink(1)}>
                <div className={s.iconActiveButtons}>
                    <Icon.Glass />
                </div>
                <span>Поесть</span>
            </div>
            <div className={clsx(s.eat, s.but, { [s.activeEat]: activeDrink === 2 })}
                 onClick={() => isActiveModal()}>
                <div className={s.iconActiveButtons}>
                    <Icon.Martini />
                </div>
                <span>Выпить</span>
            </div>
        </div>
    );
});

export default TabsCat;