import {request} from '../utils/request'
import axios from 'axios'
import {AXI} from "../libs/axi";


export async function getRestoranMini() {
    return request.get(`/api/restoran/mini`)
}

// export async function getOrderCallpay(id) {
//     return request.post(`/api/orders/callpay`,{
//         tableId:id
//     })
// }

export async function getRestoranInfoIP() {
    return request.get(`/api/payments/info`)
}

export async function getRestoranTable(number) {
    return request.get(`/api/restoran/table?table=${number}`)
}

export async function getRestoranTableId(id) {
    return request.get(`/api/restoran/tablebyid?id=${id}`)
}

export async function getPreorderTableId(id) {
    return request.get(`/api/orders/preorder?table=${id}`)
}

export async function getPreorderFastId(id) {
    return request.get(`/api/orders/preorderbyid?id=${id}`)
}


export async function getOrderTableId(data) {
    return request.post(`/api/orders/updateorder`, {...data})
}

export async function getOrderTable(data) {
    return request.post(`/api/orders/createorder`, {...data})
}

export async function getPaymentsTable(id) {
    return request.get(`/api/payments/payorder?tableId=${id}`)
}


export async function getPaymentsTableFastOrder(id) {
    return request.get(`/api/payments/payorderbyid?id=${id}`)
}

export async function getReviews(data) {
    return request.post(`/api/reviews/create`, data)
}


// Оплата кухня бар

export async function getPayOrder(id) {
    return request.get(`/api/payments/getpayorder?id=${id}`)
}

export async function getKitchenLinq(id) {
    return request.get(`/api/payments/kitchenlinq?id=${id}`)
}

export async function getBarLinq(id) {
    return request.get(`/api/payments/barlinq?id=${id}`)
}

export async function getLinqAuto(id) {
    return request.get(`/api/payments/checktransactions?id=${id}`)
}

// отмена заказа полностью перед оплатой
export async function getCancelOrder(id) {
    return request.post(`/api/orders/cancelOrder`, {id: id}
    )
}
