import React, {FC, useEffect, useRef, useState} from "react";

import ItemOrder from "../../components/ItemOrder";
import {AXI} from "../../libs/axi";
import {useParams} from 'react-router-dom';
import s from "./Categories.module.css"
import {SearchForm} from "../../components/SearchForm";
import RecomendationItem from "../../components/ModalFormItem/RecomendationItem";
import {useGetLocationData, useSetLocationData} from "../../hooks/locationData";
import Layout from "../../components/Layout";
import {ResponseData, Item} from "../../types/types";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import Preloader from "../Preloader";
import Icon from "../Icon";
import useObjectModal from "../../hooks/useObjectModal";


interface ObjectModal {
    id: number;
    count: number;
}

interface Modal {
    // objectModal: ObjectModal[],
    // setObjectModal: React.Dispatch<React.SetStateAction<ObjectModal[]>>
    modalCategories?: string | null
    modalPodbor?: string | null
    modalActions?: string | null
}

const ModalCategories: FC<Modal> = ({modalCategories = "", modalPodbor = "", modalActions = "",}) => {
    // const {id: nameId} = useParams();
    const { searchText } = useGetLocationData();
    const [arrCat, setArrCat] = useState<Item[]>([])
    const [text, setText] = useState<string>("")
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [fetching, setFetching] = useState(true);
    const { objectModal, addObjectModalItem } = useObjectModal(searchText);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const containerRef = useRef<HTMLDivElement>(null);

    console.log(page,'page')

    useEffect(() => {
        const fetchItems = async () => {
            if (!fetching) return;
            if(totalPage === page || totalPage < page) return;
            try {
                const url = modalCategories
                    ? `${AXI}/api/items/catitems?category=${modalCategories}&count=30&page=${page + 1}`
                    : modalPodbor
                        ? `${AXI}/api/items/specialcatitems?id=${modalPodbor}&count=30&page=${page + 1}`
                        : modalActions
                            ? `${AXI}/api/items/actiontitems?id=${modalActions}&count=30&page=${page + 1}`
                            : null;

                if (!url) return;

                if (url) {
                    const response = await fetch(url);
                    const data = await response.json();

                    if (data.items.length === 0 || page === data.totalPages) {
                        setFetching(false); // Конец данных
                        return;
                    }

                    setArrCat(prev => [...prev, ...data.items]);


                    setPage(prev => prev + 1);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setFetching(false);
            }
        };

        fetchItems();
    }, [fetching, page, modalCategories, modalPodbor, modalActions]);


    useEffect(() => {
        if (!isLoading) return;

        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (modalCategories) {
                    const response = await fetch(`${AXI}/api/items/catitems?category=${modalCategories}&count=30&page=1`);
                    const data = await response.json();
                    setText(data?.category?.name ? data?.category?.name : "");
                    setArrCat(data["items"]);
                    setTotalPage(data["totalPages"]);
                    // setPage(2); // Начинаем со второй страницы
                } else if (modalPodbor) {
                    const response = await fetch(`${AXI}/api/items/specialcatitems?id=${modalPodbor}&count=30&page=1`);
                    const data = await response.json();
                    setText(data?.category?.name ? data?.category?.name : "");
                    setArrCat(data["items"]);
                    setTotalPage(data["totalPages"]);
                    // setPage(2);
                } else if (modalActions) {
                    const response = await fetch(`${AXI}/api/items/actiontitems?id=${modalActions}&count=30&page=1`);
                    const data = await response.json();
                    setText(data?.category?.name ? data?.category?.name : "");
                    setArrCat(data["items"]);
                    setTotalPage(data["totalPages"]);
                    // setPage(2);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
                setFetching(false);
            }
        };

        fetchData();
    }, [isLoading, modalCategories, modalPodbor, modalActions]);


    // const handleScroll = () => {
    //     if (containerRef.current) {
    //         const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    //         if (scrollHeight - (scrollTop + clientHeight) < 100 && !fetching) {
    //             setFetching(true);
    //         }
    //     }
    // };

    const handleScroll = () => {
        if (fetching || !containerRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        if (scrollHeight - (scrollTop + clientHeight) < 100) {
            setFetching(true);
        }
    };


    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [fetching]);
    useEffect(() => {
        if (isLoading || fetching) return;

        if (modalCategories || modalPodbor || modalActions) {
            setArrCat([]);
            setPage(1);
            setFetching(true);
        }
    }, [modalCategories, modalPodbor, modalActions]);
    const setData = useSetLocationData();

    const handleClickRes = (id: number) => {
        addObjectModalItem(id,1)
    };

    return (<div id={"wrap"} className={s.modalAll} ref={containerRef}>{
            isLoading ? <Preloader/> : <>
                <div className={s.wrapper} >

                    <div className={s.title}>
                        <div className={s.Arrow}
                             onClick={() => {
                                 setData(["modalCategories", ""], ["modalPodbor", ""], ["modalActions", ""], ["titleModal", ""])
                                 setArrCat([])
                             }}>
                            <Icon.Arrow/>
                        </div>
                        {text}
                    </div>
                    {arrCat.length > 0 && arrCat.map(({id, name, img, price, discount, groupModifiers,discountprocent,hot}, index) =>
                        <ItemOrder
                            key={`${id}${name}${index}2`}
                            id={id}
                            name={name}
                            hot={hot}
                            img={img}
                            price={price}
                            discount={discount}
                            groupModifiers={groupModifiers}
                            discountprocent={discountprocent}
                            handleClickRes={handleClickRes}
                            objectModal={objectModal}
                        />
                    )}
                </div>
            </>
        } </div>
    )
}


export default ModalCategories;
