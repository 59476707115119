import React, {FC, useEffect, useState} from "react";
import s from "./Order.module.css"
import {AXI} from "../../libs/axi";
import {NavLink, useNavigate} from "react-router-dom";
import Recommendation from "../Main/Recommendation";
import Preloader from "../../components/Preloader";
import {useGetLocationData, useSetLocationData} from "../../hooks/locationData";
import clsx from "clsx";
import Layout from "../../components/Layout";
import Icon from "../../components/Icon";
import moment from "moment";
import 'moment/locale/ru';
import useObjectModal from "../../hooks/useObjectModal";
import {Item} from "../../types/types";
import {getOrderTable, getOrderTableId, getPreorderFastId, getPreorderTableId} from "../../api/restoran";



interface Childse{
    id:number
    name:string
    basicprice:number
}

interface PreodrderChilds{
    id:number
    name:string
    price:number
}

interface Group{
    id:number,
    maxAmount:number,
    childModifiers:Childse[]
}


interface ItemObject {
    id: number;
    img: string;
    price: number,
    counter: number,
    name: string;
    m_item:MItems,
    preorderItemModifications:PreodrderChilds[] | []
}

interface Modification{
    maxprice:number,
    curprice:number,
    curdiscountprocent:number,
}

interface MItems{
    m_items_modification:Modification
}

interface ItemAfter {
    preorderItems: ItemObject[],
    summ: number,
    id: number,
    itemcount: number,
    createdAt: Date,
}

interface ObjectModal {
    id: number;
    modification?:number[]
    count: number;
}
interface OrderItem {
    isHookah: boolean;
    isFastorder: boolean;
    restoranName: string;
}

export const Order: FC<OrderItem>  = ({isHookah,restoranName= '', isFastorder}) => {
    const [objectModal, setObjectModal] = useState<ObjectModal[]>([])
    const [arrPrice, setArrPrice] = useState<Item[]>([])
    const [itemAfter, setItemAfter] = useState({} as ItemAfter )
    const [activeDrink, setActiveDrink] = useState(1)
    const [discountData, setDiscountData] = useState<number>(0)
    const [isOrder, setIsOrder] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [summData, setSummData] = useState<number>(0)
    const { searchText, modal } = useGetLocationData();
    const { objectModal:ObjectFastModal, addObjectModalItem,clearModalItems } = useObjectModal(searchText,objectModal)
    const setData = useSetLocationData();
    const [table,setTable] = useState<string>("")
    const [tableName,setTableName] = useState<string>("")
    const [isButDisabled, setIsButDisabled]= useState<boolean>(false)
    const navigate = useNavigate();
    useEffect(()=>{
        setIsButDisabled(false)
    },[])

    useEffect(() => {
        const savedData = localStorage.getItem('tableId');
        if (savedData) {
            setTable(JSON.parse(savedData));
        }
        const savedDatas = localStorage.getItem('tableName');
        if (savedDatas) {
            setTableName(JSON.parse(savedDatas));
        }
    }, []);


    useEffect(() => {
        const savedData = localStorage.getItem('objectModal');
        if (savedData) {
            setObjectModal(JSON.parse(savedData));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('objectModal', JSON.stringify(objectModal));
    }, [objectModal]);

    useEffect(() => {
        let storedData = Number(localStorage.getItem('activeDrink'));
        if (storedData) {
            setActiveDrink(storedData);
        } else {
            localStorage.setItem('activeDrink', JSON.stringify(activeDrink));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('activeDrink', JSON.stringify(activeDrink));
    }, [activeDrink]);


    const handleClickRes = (id:number) => {
        addObjectModalItem(id,1)
    };



    const total = objectModal.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.count;
    }, 0);

    const objectModalFilter = objectModal.filter((el)=>el?.id)


    const handleLogin = async () => {
        try {
            if(objectModal.length > 0) {
                const response = await fetch(`${AXI}/api/orders/calc`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "items": objectModalFilter
                    }),
                });
                const data = await response.json();

                if (response.ok) {
                    setArrPrice(data.items)
                    setDiscountData(data.discount)
                    setSummData(data.summ)

                    setTimeout(() => {
                        setIsLoading(false)
                    }, 1000);
                } else {
                    throw new Error(data.message);
                }
            } else {
                setArrPrice([])
                setTimeout(() => {
                    setIsLoading(false)
                }, 1000);
            }
        } catch (error) {
            console.log(error);
            alert('Неправильные данные для расчета');
        }
    };


    useEffect(() => {
        handleLogin()
    }, [objectModal])
    useEffect(() => {
        if(ObjectFastModal.length > 0 && JSON.stringify(ObjectFastModal) !== JSON.stringify(objectModal)) {
            setObjectModal(ObjectFastModal)
        }
    }, [ObjectFastModal])

    const handleClickOrder = async () => {
        const fastOrderLocal = await localStorage.getItem('fastOrderId')
        const fastOrder = isFastorder &&  fastOrderLocal && JSON.parse(fastOrderLocal)
console.log(isFastorder,'isFastorder')
        try {
            if (!!table) {
                setIsButDisabled(true);
                let response;

                if (fastOrder) {
                    // Вызываем getOrderTableId и проверяем результат
                    response = await getOrderTableId({
                        id: Number(fastOrder.id),
                        items: objectModalFilter
                    });

                    console.log(response,'response')

                    // Если response false, вызываем getOrderTable
                    if (!response.data) {
                        response = await getOrderTable({
                            tableId: Number(table),
                            items: objectModalFilter
                        });
                    }
                } else {
                    // Если fastOrder отсутствует, вызываем getOrderTable
                    response = await getOrderTable({
                        tableId: Number(table),
                        items: objectModalFilter
                    });
                }

                const data = response.data; // Получаем данные из ответа

                if (response.status === 200) { // Проверяем статус ответа
                    await setItemAfter(data);
                    if (isFastorder) {
                        const currentTime = Date.now();
                        const limit = 2 * 60 * 60 * 1000;
                        localStorage.setItem("fastOrderId", JSON.stringify({
                            time: String(currentTime + limit),
                            id: data.id
                        }));
                    }
                    await setObjectModal([]);
                    await clearModalItems();
                    await setIsOrder(true);
                    await setIsButDisabled(false);
                } else {
                    throw new Error(data.message);
                }
            }
        } catch (error) {
            console.log(error);
            alert('Неправильные данные для заказа на стол');
        }
    };

    const handleIsOrder = async () => {
        try {
            let response;
            const savedData = await localStorage.getItem('fastOrderId');
            const idIsFastOrder = isFastorder && savedData && JSON.parse(savedData);
            console.log(isFastorder,'isFastorder',idIsFastOrder)
            if (isFastorder) {
                // Используем getPreorderFastId, если isFastorder true и есть idIsFastOrder

                if(idIsFastOrder && idIsFastOrder.id){
                response = await getPreorderFastId(idIsFastOrder.id);

                } else {
                    setItemAfter({} as ItemAfter);
                    setIsOrder(false);
                }
            } else if (!!table) {
                // Используем getPreorderTableId, если isFastorder false и есть table
                response = await getPreorderTableId(Number(table));
            } else {
                // Если данных недостаточно, просто выходим из функции
                console.warn("Недостаточно данных для выполнения запроса");
                return;
            }

            const data = response &&  response.data; // Получаем данные из ответа

            if (response && response.status === 200) { // Проверяем статус ответа
                setItemAfter(data);
                setIsOrder(data ? true : false);
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            console.log(error);
            // alert('Неправильные данные для предзаказа');
        }
    };

    useEffect(() => {
        if (!!table && typeof isFastorder === "boolean") {
            handleIsOrder();
        }
    }, [table,isFastorder]);

    const isAfter = itemAfter && Object.keys(itemAfter).length > 0;

    useEffect(() => {
        if (isOrder && objectModal.length === 0 && isAfter) {
            navigate('/payment');
        }
    }, [objectModal, itemAfter, isOrder]);


const [isOne,setIsOne]=useState(false)
    useEffect(()=>{
        if(!!modal){
            setIsOne(true)
        }

        if(isOne){
            window.location.reload()
        }
    },[modal])



    const allertTable = () => {
            alert("Отсканируйте QR вашего стола")
    }

    const funcItemModificate = (item:Group[])=>{
        if(item){
        return     item.map((el)=>
            el.childModifiers.map((e)=>{

                return e.id
            })
            ).flat()
        }
    }
    const allIdsPresent = (array1:number[],array2:number[]) => array1.every(id => array2.some(obj => obj === id));
    const formattedTime = (orderTime:Date)=> moment(orderTime).locale('ru').format('D MMMM YYYY в HH:mm');
    const areArraysEqual = (arr1: number[] | undefined, arr2: number[] | undefined): boolean => {
        if (!arr1 || !arr2 || arr1.length !== arr2.length) return false;
        return arr1.every(el => arr2.includes(el));
    };

    return (<Layout>
        {isLoading ? <Preloader/> : <div className={clsx(s.wrapper,{
            [s.wrapperBack]: !isOrder && arrPrice && arrPrice.length === 0
        })}>

            {(!isOrder) ?
                arrPrice && arrPrice.length > 0 && <div className={s.titleOrder}>Стол №{tableName}</div> :
                <div className={s.titleOrder}>Стол №{tableName}</div>
            }

            {((arrPrice && arrPrice.length > 0) || isOrder) ? <>
            <div className={clsx(s.wrapItem,{
                [s.wrapItemOrder]:isOrder,
                [s.wrapItemOrderNone]:arrPrice && arrPrice.length === 0
            })}>
                {arrPrice.length > 0 && arrPrice.map((item,index) => {
                    const arr = funcItemModificate(item.groupModifiers || [])

                    return <div key={`${index}rad`} className={s.item}>
                        <div className={s.blockLeft}>

                            <div className={s.itemImg}>
                                <img src={item.img}/>
                            </div>
                            <div className={s.desc}>
                                <div className={clsx(s.text, {
                                    [s.textNoneMargine]: item.groupModifiers
                                })}>
                                    {item.name ? item.name : "Hot spicy fried rice with omelet spicy salad"}
                                </div>

                                {item.groupModifiers && item.groupModifiers.map((els) =>
                                    els.childModifiers.map((el,index) =>
                                        <div key={`${index}rrr`}
                                            className={s.textGray}>{el.name} {el.basicprice ? `${el.basicprice} Р` : ""}</div>
                                    ))}

                                <div className={s.count}>
                                    <div className={s.orderButtons}>
                                        <div className={s.buttonGray}>
                                            <button
                                                className={s.minus}
                                                onClick={() => {
                                                    setObjectModal(prevState => {
                                                        setData(["searchText", searchText ? searchText + 1 : item.id + 1])
                                                        const arr = funcItemModificate(item.groupModifiers || [])

                                                        const updatedState = prevState.map(el =>
                                                            (el.id === item.id && allIdsPresent(el.modification || [], arr || [])) ? {
                                                                ...el,
                                                                count: el.count - 1
                                                            } : el
                                                        );
                                                        return updatedState.filter(item => item.count > 0);
                                                    });
                                                }}
                                            >
                                                <Icon.Minus/>
                                            </button>
                                            {objectModal.find(el => el.id === item.id && allIdsPresent(el.modification || [], arr || []))?.count || 0}
                                            <button
                                                className={s.plus}
                                                onClick={() => {
                                                    setObjectModal(prevState => {
                                                            setData(["searchText", searchText ? searchText + 1 : item.id + 1])


                                                            return prevState.map(el => {
                                                                return (el.id === item.id && allIdsPresent(el.modification || [], arr || [])) ? {
                                                                    ...el,
                                                                    count: el.count + 1
                                                                } : el
                                                            })
                                                        }
                                                    );
                                                }}
                                            >
                                            <Icon.Plus/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={s.blockRight}>
                            <div
                                onClick={() => {
                                    setObjectModal(prevState => {
                                        setData(["searchText", searchText ? searchText + 1 : item.id + 1])
                                        const updatedState = prevState.map(el =>
                                            (el.id === item.id && JSON.stringify(el.modification) === JSON.stringify(arr)) ? {
                                                ...el,
                                                count: 0
                                            } : el
                                        );
                                        return updatedState.filter(item => item.count > 0);
                                    });
                                }}
                                className={s.iconRight}>
                                <svg width="11" height="12" viewBox="0 0 11 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.24264 1.75736L9.72792 10.2426M9.72792 1.75736L1.24264 10.2426"
                                          stroke="#8E919F" strokeWidth="2" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div className={s.summ}>


                                {(item.discount && item.discount !== item.price) ?
                                    <div className={s.priceDicount}>
                                     <span
                                         className={s.discounts}> {item.discount * ( objectModal.find(
                                         el => el.id === item.id && areArraysEqual(el.modification, arr)
                                     )?.count || 0)} ₽</span>
                                        <span
                                            className={s.pricesDisc}> {item.price * ( objectModal.find(
                                            el => el.id === item.id && areArraysEqual(el.modification, arr)
                                        )?.count || 0)} ₽ </span>
                                    </div>

                                    : <span
                                        className={s.price}>{item.price * ( objectModal.find(
                                        el => el.id === item.id && areArraysEqual(el.modification, arr)
                                    )?.count || 0)} ₽ </span>}
                            </div>

                        </div>
                    </div>
                })}
            </div>


                {arrPrice && arrPrice.length > 0 && <div className={s.sostav}>
                    <div className={s.titleSostavItem}>
                        <div className={s.titleTextSostav}>{isOrder && isAfter ? "Состав дозаказа" : "Состав заказа"}</div>
                    </div>
                    <div className={s.titleSostavItem}>
                        <div className={s.titleTextSostavPos}> {total} позиции</div>
                        <div className={s.positionSum}>{summData + discountData} ₽</div>
                    </div>
                    <div className={s.titleSostavItemSkidka}>
                        <div className={s.titleTextSostavPos}>Скидка</div>
                        <div className={s.positionSumDisc}>-{discountData} ₽</div>
                    </div>
                    <div className={s.titleSostavItems}>

                        <div className={s.titleTextSostavTog}>Итого</div>
                        <div className={s.positionItog}>{summData} ₽</div>
                    </div>
            <div className={s.btnFixed}>

                <div className={s.textBtnFixed}>
                    <div className={s.fistText}>Итого</div>
                   <div> {summData} ₽</div>
                </div>

                    <div onClick={!!table ? handleClickOrder : allertTable} className={clsx(s.ButtonOrder, {
                        [s.btnActive]: arrPrice.length > 0 ,
                        [s.disabled]: isButDisabled
                    })}>
                        {isOrder && isAfter ? "Создать дозаказ" : "Создать заказ"}
                    </div>
            </div>

                    <div className={s.descriptionText}>
                        После нажатия на кнопку «Создать заказ» официант подойдет к вам для уточнения деталей
                    </div>

                </div>}

                {itemAfter && itemAfter?.preorderItems?.length > 0 &&

                    <div className={s.titleSostav}>
                        <div className={s.titleTextSostav}>Товары в заказе</div>

                        {itemAfter?.preorderItems?.map((el, index) =>
                            <div key={`${index}sss`} className={s.itemsBlockSostav}>
                                <div className={s.item}>
                                    <div className={s.blockRightOrder}>
                                        <div className={s.textOrder}>
                                            {el.name ? el.name : ""}
                                            <span> {el.counter > 1 ? !!el?.m_item?.m_items_modification?.curdiscountprocent ?
                                                <span className={s.discounts}>- {el.price} ₽ {" "}
                                                    <span
                                                        className={s.pricesDisc}>
                                         - {el.m_item.m_items_modification.maxprice} ₽
                                      </span>
                                    </span> :

                                                <span>- {el.price} ₽ </span> : ""} - {el.counter} шт.</span>
                                            {el.preorderItemModifications?.length > 0 && el.preorderItemModifications.map((j, i) =>
                                                <div key={`${i}22ss`} className={s.textPreorder}>
                                                    {j.name} {j.price ? `${j.price} ₽` : ""}
                                                </div>
                                            )}
                                        </div>

                                        <div className={s.sumOrder}>
                                            {!!el?.m_item?.m_items_modification?.curdiscountprocent ?
                                                <span className={s.discounts}>{el.price * el.counter} ₽ {" "}
                                                    <span
                                                        className={s.pricesDisc}>
                                          {el.m_item.m_items_modification.maxprice * el.counter} ₽
                                      </span>
                                    </span> :
                                                <span>{el.price * el.counter} ₽ </span>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>)}
                    </div>
                }
                { isOrder && isAfter && arrPrice.length === 0 && <div className={s.btnFixed}>
                    <div className={s.textBtnFixed}>
                        <div className={s.fistText}>Итого</div>
                        <div> {itemAfter.summ} ₽</div>
                    </div>

                    <NavLink className={clsx(s.ButtonOrder, {
                        [s.btnActive]: arrPrice.length > 0,
                        [s.disabled]: isButDisabled
                    })} to={"/payment"}>
                        Оплатить
                    </NavLink>
                </div>}

                <div id={"rec"} className={s.wrapperRec}>
                    <Recommendation handleClickRes={handleClickRes}
                                    objectModal={ObjectFastModal} active={activeDrink}/>
                </div>
            </> : !isOrder && <div className={s.wrapperMenu}>
                <div className={s.title}>Добро пожаловать <br/> в {restoranName}!</div>
                <div className={s.iconMenu}>
                    <svg width="106" height="106" viewBox="0 0 106 106" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.9855 23.1425L15.9192 23.1823L15.8598 23.2318C14.521 24.3475 13.9062 25.7478 13.9062 27.1406C13.9063 28.5074 14.4976 29.7868 15.4179 30.7071C16.194 31.4833 17.3992 31.9375 18.6094 31.9375C19.8372 31.9375 21.1575 31.4692 22.1211 30.2927C23.0487 29.3426 23.5 28.0679 23.5 26.8594C23.5 25.6393 23.034 24.3692 21.975 23.575C21.1469 22.9539 20.2316 22.4726 19.195 22.3506C18.1382 22.2263 17.0747 22.489 15.9855 23.1425ZM15.4179 44.6679L15.3787 44.7071L15.3441 44.7503C13.5318 47.0157 13.9258 49.84 15.4179 51.3321C17.3084 53.2226 20.1916 53.2226 22.0821 51.3321C23.9726 49.4416 23.9726 46.5584 22.0821 44.6679C20.1916 42.7774 17.3084 42.7774 15.4179 44.6679ZM74.625 92H15.375C7.30228 92 1 85.6977 1 77.625V18.375C1 10.3023 7.30228 4 15.375 4H74.625C82.6977 4 89 10.3023 89 18.375V77.625C89 85.6977 82.6977 92 74.625 92ZM71.25 22.625H35.25C33.8978 22.625 32.6932 23.0801 31.8241 23.9491C30.9551 24.8182 30.5 26.0228 30.5 27.375C30.5 29.7362 32.3839 32.125 35.25 32.125H71.25C72.5127 32.125 73.6918 31.5037 74.5352 30.6602C75.3787 29.8168 76 28.6377 76 27.375C76 24.5089 73.6112 22.625 71.25 22.625ZM71.25 43.25H35.25C32.3839 43.25 30.5 45.6388 30.5 48C30.5 50.3612 32.3839 52.75 35.25 52.75H71.25C72.5127 52.75 73.6918 52.1287 74.5352 51.2852C75.3787 50.4418 76 49.2627 76 48C76 46.7373 75.3787 45.5582 74.5352 44.7148C73.6918 43.8713 72.5127 43.25 71.25 43.25ZM71.25 63.875H35.25C32.3839 63.875 30.5 66.2638 30.5 68.625C30.5 69.9772 30.9551 71.1818 31.8241 72.0509C32.6932 72.9199 33.8978 73.375 35.25 73.375H71.25C73.6112 73.375 76 71.4911 76 68.625C76 67.3623 75.3787 66.1832 74.5352 65.3398C73.6918 64.4963 72.5127 63.875 71.25 63.875ZM22.1304 65.3419C21.3122 64.3004 19.9549 63.875 18.75 63.875C17.5279 63.875 16.1491 64.3126 15.3349 65.3869C13.6658 67.4828 13.4008 70.3999 15.4677 72.0063C17.3423 73.8315 20.1587 73.8313 22.033 72.0055C23.051 71.2089 23.5 69.9632 23.5 68.7656C23.5 67.5611 23.0518 66.2909 22.1304 65.3419Z"
                            stroke="#C7C9D2" strokeWidth="2"/>
                        <circle cx="84" cy="82" r="22" fill="#F85D42"/>
                        <path d="M84 74V89.9998" stroke="white" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M76 82H91.9998" stroke="white" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </div>
                <div className={s.textMenu}>
                    Скорее попробуйте наши <br/>блюда и напитки
                </div>
                <NavLink className={s.hrefDecor} to={"/menupage"}>
                    <div className={s.buttMenu}>
                        Смотреть меню
                    </div>
                </NavLink>
            </div>
            }
        </div>}</Layout>
    )
}