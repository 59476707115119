import React, {FC, useEffect, useState} from "react";
import {AxiosResponse} from "axios";
import s from "./KitchenBarPayment.module.css"
import {AXI} from "../../libs/axi";
import {NavLink, useNavigate} from "react-router-dom";
import Recommendation from "../Main/Recommendation";
import Preloader from "../../components/Preloader";
import {useGetLocationData, useSetLocationData} from "../../hooks/locationData";
import clsx from "clsx";
import Layout from "../../components/Layout";
import Icon from "../../components/Icon";
import moment from "moment";
import 'moment/locale/ru';
import useObjectModal from "../../hooks/useObjectModal";
import {Item} from "../../types/types";
import {TabsPayments} from "../../components/TabsPayments";
import {
    getBarLinq,
    getKitchenLinq, getLinqAuto,
    getPaymentsTable,
    getPaymentsTableFastOrder,
    getPayOrder,
    getRestoranTable
} from "../../api/restoran";
import Popup from "../../components/Popup";




interface KitchenBar {
    orderId: string;
    formUrl: string;
}

interface ItemKitchin {
    amount:number,
    isPayed:boolean,
    linq:KitchenBar | KitchenBarError,
}

interface KitchenBarError {
    errorCode: string;
    errorMessage: string;
}

interface ItemKitchenBar {
    food: ItemKitchin ;
    bar: ItemKitchin ;
}

interface LinqResponse {
    Linq: string;
    status: string;
}

interface OrderItem {
    isHookah: boolean;
    isDifferent: boolean;
    isFastorder: boolean;
    restoranName: string;
}

export const KitchenBarPayment: React.FC<OrderItem> = ({ restoranName = "" ,isFastorder}) => {
    const navigate = useNavigate();
    const { error } = useGetLocationData();
    const setData = useSetLocationData();
    const [isErrorPayment, setIsErrorPayment]=useState(false)
    const [itemAfter, setItemAfter] = useState({} as ItemKitchenBar);
    const [selectedOption, setSelectedOption] = useState<"food" | "bar" | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [table, setTable] = useState<string>("");
    const [idOrder, setIdOrder] = useState<string>("");
    const [datas, setDatas] = useState<LinqResponse | null>(null);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response: AxiosResponse<LinqResponse> = await getLinqAuto(idOrder);
                setDatas(response.data);

                if (response.data.Linq) {

                    window.location.replace(response.data.Linq);
                }
            } catch (error) {
                setErrors(error instanceof Error ? error.message : 'An error occurred');
            } finally {
                setLoading(false);
            }
        };

        if(idOrder){
        fetchData();
        }

        const intervalId = setInterval(fetchData, 5000);


        return () => clearInterval(intervalId);
    }, [idOrder, navigate]); // Зависимость от idOrder и navigate
    //
    // useEffect(() => {
    //     setIsLoading(true)
    //     const savedData = localStorage.getItem("tableId");
    //
    //     const savedFastOrderId: string | null | undefined = localStorage.getItem('fastOrderId');
    //
    //     const idIsFastOrder = isFastorder && savedFastOrderId && JSON.parse(savedFastOrderId)
    //
    //     if (idIsFastOrder) {
    //         setIdOrder(JSON.parse(idIsFastOrder.id));
    //     }
    //     if (savedData) {
    //         setTable(JSON.parse(savedData));
    //     }
    //         console.log(idIsFastOrder,"idIsFastOrderidIsFastOrderidIsFastOrderidIsFastOrder",savedData)
    // }, []);

    useEffect(() => {
        if (isFastorder !== undefined) {
            // Выполняем действия, когда isFastorder пришел
            const savedFastOrderId = localStorage.getItem('fastOrderId');
            const idIsFastOrder = isFastorder && savedFastOrderId && JSON.parse(savedFastOrderId);

            if (idIsFastOrder) {
                setIdOrder(JSON.parse(idIsFastOrder.id));
            }

            const savedData = localStorage.getItem("tableId");
            if (savedData) {
                setTable(JSON.parse(savedData));
            }

            console.log(idIsFastOrder, "idIsFastOrder", savedData);
        }
    }, [isFastorder]);



    // useEffect(() => {
    //     if (table && !idOrder && !isFastorder) {
    //
    //         console.log('как ты сюда попал?')
    //         getPaymentsTable(table).then((res: any) => setItemAfter(res.data));
    //         // getPaymentsTableFastOrder(table).then((res: any) => setItemAfter(res.data));
    //         setIsLoading(false)
    //     }
    //     if (!!idOrder) {
    //         // getPaymentsTable(table).then((res: any) => setItemAfter(res.data));
    //         // getPaymentsTableFastOrder(idOrder).then((res: any) => setItemAfter(res.data));
    //         console.log("попал?")
    //         getPayOrder(idOrder).then((res: any) => setItemAfter(res.data));
    //         setIsLoading(false)
    //     }
    // }, [table,idOrder]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                if (table && !idOrder && !isFastorder) {
                    const res = await getPaymentsTable(table);
                    setItemAfter(res.data);
                }

                if (idOrder) {
                    const res = await getPayOrder(idOrder);
                    setItemAfter(res.data);
                }
            } catch (error) {
                console.error("Ошибка при загрузке данных:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [table, idOrder, isFastorder]);

    const [isFastorderReady, setIsFastorderReady] = useState(false);

    useEffect(() => {
        if (isFastorder !== undefined) {
            setIsFastorderReady(true);
        }
    }, [isFastorder]);

    useEffect(() => {
        if ((itemAfter.food?.isPayed || itemAfter.food?.amount === 0) && (itemAfter.bar?.isPayed || itemAfter.bar?.amount === 0)) {
            navigate("/paysucess");
        }
    }, [itemAfter]);

    const handlePayment = async () => {
        if (!selectedOption || !itemAfter[selectedOption]) {
            alert("Пожалуйста, выберите способ оплаты");
            return;
        }

        try{

            if(selectedOption === "food"){

           const pay =await getKitchenLinq(idOrder).then((res: any) =>  res.data);

           if(pay?.linq?.formUrl){
               window.location.href = pay.linq.formUrl;
           } else if (pay?.linq?.errorCode) {
               alert(pay.linq.errorMessage);
           } else {
               alert("Ошибка: данные для оплаты недоступны.");
           }

            } else if (selectedOption === "bar"){

                const pay = await  getBarLinq(idOrder).then((res: any) =>  res.data);

                if(pay?.linq?.formUrl){
                    window.location.href = pay.linq.formUrl;
                } else if (pay?.linq?.errorCode) {
                    alert(pay.linq.errorMessage);
                } else {
                    alert("Ошибка: данные для оплаты недоступны.");
                }
            }

        } catch (error){
            console.log("Ошибка запроса",error)
        }

        // const selectedPayment = itemAfter[selectedOption].linq;
        // if (selectedPayment && "formUrl" in selectedPayment) {
        //     window.location.href = selectedPayment.formUrl;
        // } else if ("errorCode" in selectedPayment) {
        //     alert(selectedPayment.errorMessage);
        // } else {
        //     alert("Ошибка: данные для оплаты недоступны.");
        // }
    };

    useEffect(()=>{
        if(!!error){
            // alert('Ошибка терминала, попробуйте ещё раз')

            setIsErrorPayment(true)
            setTimeout(()=>{
                setData(["error",""])
            },6000)
        } else {
            setIsErrorPayment(false)
        }
    },[error])

// console.log( selectedOption && itemAfter[selectedOption],'itemAfter[selectedOption]',selectedOption)

    return (
        <Layout>
            {isLoading ? (
                <Preloader />
            ) : (
                isFastorderReady && <div className={clsx(s.wrapper)}>
                    {isErrorPayment && <Popup title={"Ошибка терминала"} description={'Попробуйте ещё раз'}/>}
                    <div className={s.titleOrder}>
                        <div className={s.wrapperTitle}>
                            <NavLink className={s.Arrow} to={"/payment"}>
                                <Icon.Arrow />
                            </NavLink>
                            Выберите чек
                        </div>
                    </div>

                    <div className={s.options}>
                        { !itemAfter?.food?.isPayed && itemAfter?.food?.amount > 0 &&
                        <div >
                            <label className={s.inp}>
                                <div >    <Icon.Cook /> Кухня</div >

                                <input
                                    type="radio"
                                    name="paymentOption"
                                    value="food"
                                    checked={selectedOption === "food"}
                                    onChange={() => setSelectedOption("food")}
                                    // disabled={!itemAfter.food || "errorCode" in itemAfter.food}
                                />

                                <div className={s.priceWrap}>
                                    <div>{itemAfter?.food?.amount} ₽</div>
                                <div className={clsx(s.btnRadio,{
                                    [s.btnRadioActive]:selectedOption === "food"
                                })}>
                                    <span/>
                                </div>
                                </div>

                            </label>
                        </div>}
                        { !itemAfter?.bar?.isPayed && itemAfter?.bar?.amount > 0 &&
                        <div >
                            <label className={s.inp}>
                                <div>    <Icon.Bar />Бар</div>
                                <input
                                    type="radio"
                                    name="paymentOption"
                                    value="bar"
                                    checked={selectedOption === "bar"}
                                    onChange={() => setSelectedOption("bar")}
                                    // disabled={!itemAfter.bar || "errorCode" in itemAfter.bar}
                                />

                                <div className={s.priceWrap}>
                                    <div>{itemAfter?.bar?.amount} ₽</div>
                                <div className={clsx(s.btnRadio,{
                                    [s.btnRadioActive]:selectedOption === "bar"
                                })}>
                                    <span/>
                                </div>
                                </div>
                            </label>
                        </div>}

                        <div className={s.allPrice}>
                            <span>Общий счет</span>
                            <span>{(itemAfter?.bar?.isPayed ? 0 : itemAfter?.bar?.amount)  + (itemAfter?.food?.isPayed ? 0 : itemAfter?.food?.amount)} ₽</span>
                        </div>

                    <div className={s.info}>
                        Извините за неудобство, но в нашем ресторане <br/>раздельная оплата кухни и бара
                    </div>
                    </div>

                    <div className={s.btnFixed}>
                        <div
                            className={clsx(s.ButtonOrder, {
                                [s.disabled]: !selectedOption || !itemAfter[selectedOption],
                            })}
                            onClick={handlePayment}

                        >
                            {!selectedOption || !itemAfter[selectedOption] ? "Выберите чек для оплаты" : `Оплатить ${itemAfter[selectedOption]?.amount} ₽`}
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
};