import s from "./ItemOrder.module.css";
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import LoadableImage from "../LoadableImage/LoadableImage";
import {useGetLocationData, useSetLocationData} from "../../hooks/locationData";
import LazyLoad from "../LazyLoad";
import {NavLink} from "react-router-dom";
import {AXI} from "../../libs/axi";
import Icon from "../Icon";
import {Group} from "../../types/types";
import ModalFastOrderItem from "../ModalFastOrderItem";

interface ObjectModal {
    id: number;
    count: number;
}

interface ItemCatigories {
    id: number;
    img: string;
    price: number,
    discount?: number | null,
    discountprocent?: number | null;
    description?: string | null;
    hot?: boolean | null;
    mass?: number | null;
    ccal?: number | null;
    small?: boolean;
    name: string;
    handleClickRes?: (id: number) => void;
    objectModal?: ObjectModal[];
    groupModifiers?: Group[],
}
interface ObjectModals {
    modification: number[];
    id: number;
    count:number;
}
const ItemOrderMenu = (props: ItemCatigories) => {
    const {id, name, img, description, price, discount, small,mass, handleClickRes,ccal,groupModifiers, objectModal, discountprocent,hot} = props
    const isObject = objectModal && objectModal.filter((el) => el.id === id).length > 0
    const setData = useSetLocationData();
    const { searchText } = useGetLocationData();
    const [isFastClick,setIsFastClick]=useState(false)
    const isObjectModal = objectModal && objectModal.find((el)=> el.id === id)

    const handleFastClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        if(groupModifiers && groupModifiers.length > 0 ){
            setIsFastClick(!isFastClick)
        } else {
            const savedData: string | null | undefined = localStorage.getItem('objectModal');
            const dataFast: ObjectModal[] =  savedData ? JSON.parse(savedData)  : []

            if(isObjectModal){
                setData(["searchText", searchText ? searchText + 1 : id + 1]);
                const remove = dataFast.filter((el)=> el.id !== id)

                console.log(remove,'remove')
                localStorage.setItem('objectModal', JSON.stringify(remove));
            } else {
                setData(["searchText", searchText ? searchText + 1 : id + 1]);
                const addFast = [...dataFast,{
                    id:id,
                    modification:[],
                    count: 1,
                }]

                console.log(addFast,'addFast')
                localStorage.setItem('objectModal', JSON.stringify(addFast));
            }
        }
    };
    useEffect(() => {
        localStorage.setItem('objectModal', JSON.stringify(objectModal));
    }, [objectModal]);
    const handleButtonClick = () => {
        setData(["modal", id])
    };


    return (
        <>
        <div className={clsx(s.item, {
            [s.smallItem]: small
        })} key={`${id}${name}`}>
            <div>
            <div onClick={handleButtonClick} className={s.menuButtonCategory}>
                {img ?
                    // <img className={s.img} src={img} />
                    <LoadableImage
                        src={AXI.includes("http://185.87.192.175:4000") ? `http://185.87.192.175${img}` : img}/>
                    // <LazyLoad src={img} alt={name} />
                    : <div className={s.noFoto}> <Icon.NoFoto/><div>нет фото</div></div>
                }
                {hot && <div className={s.wrapIcon}>  <Icon.Hot/> </div>}
                <div className={clsx(s.fast,{
                    [s.fastActive]: isObjectModal,
                    [s.notFastActive]: !isObjectModal
                })} onClick={handleFastClick}> {isObjectModal ? <Icon.OrderActive/> : <Icon.Plus />} </div>
            </div>
            <div className={s.name}>{name}</div>
                {mass && <div className={s.gram}>{Math.ceil(mass)} гр</div>}
            <div className={s.price}>    {discount ?
                <div className={s.discount}>
                    <div><span className={s.priceDisc}>{price} ₽</span> <span
                        className={s.discPersent}>-{discountprocent}%</span></div>
                    <span className={s.disc}>{discount} ₽</span></div> :
                <span> {price} ₽</span>

            }</div>
            <div>
            </div>
            </div>
        </div>
            {isFastClick && <ModalFastOrderItem setIsFastClick={setIsFastClick} id={id} name={name} img={img} price={price} discount={discount || null} description={description || null}  groupModifiers={groupModifiers} hot={hot || null} ccal={ccal || null} mass={mass || null} />}

        </>
    )
}

export default ItemOrderMenu