import React, {FC, useEffect, useState} from 'react';
import s from './ModalService.module.css';
import clsx from 'clsx';
import Icon from "../Icon";
import {callService} from "../../utils/callService";
import {getRestoranInfoIP, getRestoranMini, getRestoranTable, getRestoranTableId} from "../../api/restoran";
import {useGetLocationData} from "../../hooks/locationData";
import TableId from "../../hooks/tableId";

interface ModalProps {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
    isHookah: boolean;
}


interface ItemRestoranInfo {
    name: string,
    ipname: string,
    phone: string,
    inn: string,
    address: string
}

interface TableInfo {
    id: number,
    number: number,
    orderStatus: number,
    description: string,

}

const ModalService: FC<ModalProps> = ({setIsOpen, isOpen, isHookah}) => {
    const closeModal = () => {
        setIsOpen(false);
    };
    const dataTable = useGetLocationData();
    const [isButDisabledOficiant, setIsButDisabledOficiant] = useState(false);
    const [isButDisabledHookah, setIsButDisabledHookah] = useState(false);
    const [isButDisabledCallpay, setIsButDisabledCallpay] = useState(false);
    const [infoTable, setInfoTable] = useState({} as TableInfo)

    const [restoranInfo, setRestoranInfo] = useState({} as ItemRestoranInfo);
    useEffect(() => {

        let firstVisitTime = localStorage.getItem('firstVisitTime');

        if (!firstVisitTime) {

            firstVisitTime = Date.now().toString();
            localStorage.setItem('firstVisitTime', firstVisitTime);
        }

        const disableDuration = 10 * 60 * 1000;
        const remainingTime = Number(firstVisitTime) + disableDuration - Date.now();

        if (remainingTime > 0) {
            setIsButDisabledCallpay(true);

            setTimeout(() => {
                setIsButDisabledCallpay(false);
            }, remainingTime);
        }
    }, []);

    useEffect(() => {
        const checkButtonState = (key: string, setDisabled: React.Dispatch<React.SetStateAction<boolean>>) => {
            const disableUntil = localStorage.getItem(key);
            if (disableUntil && Date.now() < Number(disableUntil)) {
                setDisabled(true);
                setTimeout(() => setDisabled(false), Number(disableUntil) - Date.now());
            }
        };

        checkButtonState('disable_oficiant_until', setIsButDisabledOficiant);
        checkButtonState('disable_hookah_until', setIsButDisabledHookah);
    }, []);
    const [table, setTable] = useState<string>(dataTable.table)
    useEffect(() => {
        if(isOpen){

        const savedData = localStorage.getItem('tableId');
        if (savedData) {
            setTable(JSON.parse(savedData));
        }
        }
    }, [isOpen]);

    useEffect(() => {
        getRestoranInfoIP().then((res: any) => setRestoranInfo(res.data));
    }, []);

    useEffect(() => {
        const fetchTableInfo = async () => {
            try {
                if (table) {
                    const res: any = await getRestoranTableId(table);
                    setInfoTable(res.data);
                }
            } catch (error) {
                console.error('Error fetching table info:', error);
            }
        };

        if(table){
        fetchTableInfo();
        }
    }, [table]);


    return (
        <div
            className={clsx(s.overlay, {
                [s.visible]: isOpen,
            })}
            onClick={closeModal}
        >
            <div
                className={clsx(s.modal, {
                    [s.visible]: isOpen,
                })}
                onClick={(e) => e.stopPropagation()} // Остановить событие, чтобы клик на модальном окне не закрыл его
            >
                <div className={s.modalContent}>
                    {/*<div className={s.back} onClick={closeModal}>*/}
                    {/*    <Icon.Close/>*/}
                    {/*</div>*/}
                    <h2>Сервис</h2>
                    <div className={s.tableInfo}>
                        <div className={s.wrapper}>
                            <div className={s.icon}>
                                <Icon.Table/>
                            </div>
                            <div className={s.item}>
                                <div className={s.title}>Номер стола</div>
                                <div className={s.infoText}>{infoTable?.description}</div>
                            </div>
                        </div>
                    </div>

                    <div className={s.wrapperButtons}>


                    <div onClick={() => callService('oficiant', setIsButDisabledOficiant, table)}
                         className={clsx(s.ButtonItog, {
                             [s.disabled]: isButDisabledOficiant
                         })}>
                        <div dangerouslySetInnerHTML={{__html:isButDisabledOficiant ? "Ожидайте <br / > официанта" : "Позвать <br / > официанта"}}/>

                        <div className={s.iconOredr}>
                            <Icon.Oficiant />
                        </div>
                    </div>

                    {isHookah && <div onClick={() => callService('hookah', setIsButDisabledHookah, table)}
                                      className={clsx(s.ButtonItog, {
                                          [s.disabled]: isButDisabledHookah
                                      })}>
                        <div dangerouslySetInnerHTML={{__html:isButDisabledHookah ? `Ожидайте  <br / > кальянщика` : 'Позвать <br / > кальянщика'}}/>


                        <div className={s.iconOredr}>
                            <Icon.Hookah />
                        </div>
                    </div>}
                    </div>

                    {/*<div onClick={() => callService('callpay', setIsButDisabledCallpay, table)}*/}
                    {/*     className={clsx(s.ButtonItogOrange, {*/}
                    {/*         [s.disabledOrange]: isButDisabledCallpay*/}
                    {/*     })}>*/}
                    {/*     Вызвать счет*/}
                    {/*</div>*/}
                </div>
                <div className={s.textInfo}>
                    {restoranInfo && restoranInfo.address && <div><b>Адрес:</b>{restoranInfo.address}</div>}
                    {restoranInfo && restoranInfo.phone && <div><b>Телефон:</b>{restoranInfo.phone}</div>}
                    {restoranInfo && restoranInfo.ipname &&
                    <div><b>Наименование юр.лица/ИП:</b>{restoranInfo.ipname}</div>}
                    {restoranInfo && restoranInfo.inn && <div><b>ИНН:</b>{restoranInfo.inn}</div>}
                </div>
            </div>
        </div>
    );
};

export default ModalService;
