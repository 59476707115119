export const IconSBP =()=>{
return(

    <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 3.95703L2.20144 7.89196V10.2922L0.00257532 14.2194L0 3.95703Z" fill="#5B57A2"/>
        <path d="M8.45312 6.45967L10.516 5.19535L14.7377 5.19141L8.45312 9.04135V6.45967Z" fill="#D90751"/>
        <path d="M8.44126 3.93372L8.45293 9.14343L6.24634 7.7876V0L8.44126 3.93372Z" fill="#FAB718"/>
        <path d="M14.7375 5.19168L10.5156 5.19562L8.44126 3.93372L6.24634 0L14.7375 5.19168Z" fill="#ED6F26"/>
        <path d="M8.45293 14.2415V11.7139L6.24634 10.3838L6.24755 18.1791L8.45293 14.2415Z" fill="#63B22F"/>
        <path d="M10.5103 12.9884L2.20129 7.89196L0 3.95703L14.7282 12.9832L10.5103 12.9884Z" fill="#1487C9"/>
        <path d="M6.24805 18.179L8.45312 14.2414L10.5108 12.9885L14.7287 12.9834L6.24805 18.179Z" fill="#017F36"/>
        <path d="M0.00292969 14.2191L6.26443 10.3834L4.15934 9.0918L2.20179 10.2919L0.00292969 14.2191Z" fill="#984995"/>
    </svg>



)
}