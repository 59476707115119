import s from "./Popup.module.css";
import React, {useEffect, useState} from "react";
import Icon from "../Icon";
import {NavLink} from "react-router-dom";
import animationData from './animation.json';
import Player from "react-lottie-player";


interface ItemCatigories {
    title:string;
    description:string;
}



const Popup = (props: ItemCatigories) => {
    const { title,description } = props;
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setIsVisible(false), 5000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {isVisible && (
                <div className={`${s.wrapper} ${s.animate}`}>
                    <div className={s.type}>
                        <div className={s.animation}>
                            <Player
                                loop
                                play
                                animationData={animationData}
                                style={{ width: 50, height: 50 }}
                            />

                        </div>
                        <div className={s.item}>

                        <div className={s.title}>{title}</div>
                        <div className={s.description}>{description}</div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Popup