import React, {FC, useEffect, useRef, useState} from "react";
import {useNavigate, useLocation } from "react-router-dom";


import s from "./Catigories.module.css"
import {AXI} from "../../../libs/axi";
import {useSetLocationData} from "../../../hooks/locationData";

interface ItemCatigories {
    id: number;
    img: string;
    description: string | null;
    name: string;
}

interface Item {
    active?: number;
}


const Catigories = ({ active = 1 }) => {
    const [arrCat, setArrCat] = useState<ItemCatigories[]>([]);
    const listRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const setData = useSetLocationData();
    useEffect(() => {
        fetch(`${AXI}/api/categories?id=${active}`)
            .then(res => res.json())
            .then((data: ItemCatigories[]) => {
                setArrCat(data);
            })
            .catch(error => {

            });
    }, [active]);

    useEffect(() => {
        const handleHashChange = () => {
            // Извлекаем параметры из URL
            const idFromUrl = location.hash.replace('#', '');

            // Если idFromUrl существует, то выполняем прокрутку
            if (idFromUrl) {
                const targetElement = document.getElementById(idFromUrl);
                if (targetElement) {
                    window.scrollTo({
                        top: targetElement.offsetTop + 70,
                        behavior: 'smooth',
                    });
                }
            }
        };

        // Слушаем изменения hash
        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [location.hash]);
    const handleLinkClick = (id: number) => {
        navigate(`/menupage?categories=${id}`);
    };


    return (
        <div className={s.wrapper}>
            <div ref={listRef} className={s.list}>
                {arrCat.length > 0 &&
                arrCat.map(({ id, name, img }) => (
                    <div key={`${id}${name}`} className={s.item}>
                        <button className={s.menuButtonCategory}
                                // onClick={() => handleLinkClick(id)}
                        >
                            <div
                                className={s.link}
                                 // to={`/categories/${id}`}
                                onClick={()=> setData(["modalCategories",id])}
                            >
                            {img && (
                                <img
                                    src={
                                        !img.includes('nophoto.png')
                                            ? `${img}`
                                            : `https://edabirja.ru/img/no-photos.png`
                                    }
                                />
                            )}
                            </div>
                        </button>
                        <div id={id.toString()}>{name}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Catigories;
