import qs from "qs";
import { useNavigate, useLocation, NavigateFunction  } from "react-router-dom";

export const useSetLocationData = () => {
    const navigate: NavigateFunction = useNavigate();
    let location = useLocation();
    const parameters = qs.parse(location.search.slice(1));

    return (...args: [string, any][]) => {
        const newParams = { ...parameters };

        args.forEach(([name, val]) => {
            if (name) {
                newParams[name] = val === '' ? null : val;
            }
        });

        navigate({
            search: qs.stringify(newParams),
        });
    };
};

// export const useGetLocationData = () => {
//   let location = useLocation();
//   const currentParameters = qs.parse(location.search.slice(1));
//
//   const checkEmpty = (name:any) => (name ? String(name) : "");
//
//   const {
//       table,
//     searchField,
//     searchText,
//     modal,
//       modalCategories,
//       animation,
//   } = currentParameters;
//
//   return {
//     ...currentParameters,
//
//     searchField: checkEmpty(searchField),
//       table: checkEmpty(table),
//     modal: checkEmpty(modal),
//       modalCategories: checkEmpty(modal),
//       animation: checkEmpty(animation),
//
//     searchText: checkEmpty(searchText),
//
//   };
// };
export const useGetLocationData = () => {
    let location = useLocation();
    const currentParameters = qs.parse(location.search.slice(1));

    const checkEmpty = (name: any) => (name ? String(name) : "");

    const {
        table,
        searchField,
        searchText,
        modal,
        modalCategories,
        animation,
        modalActions,
        titleModal,
        modalPodbor,
        // ?orderId=87&amount=920&bar=true&lang=ru
        orderId,
        amount,
        error,
        food,
        bar,
        lang,
        age18,
    } = currentParameters;

    return {
        ...currentParameters,

        searchField: checkEmpty(searchField),
        table: checkEmpty(table),
        modal: checkEmpty(modal),
        orderId: checkEmpty(orderId),
        amount: checkEmpty(amount),
        error: checkEmpty(error),
        bar: checkEmpty(bar),
        age18: checkEmpty(age18),
        food: checkEmpty(food),
        lang: checkEmpty(lang),
        modalCategories: checkEmpty(modalCategories),
        modalPodbor: checkEmpty(modalPodbor),
        modalActions: checkEmpty(modalActions),
        titleModal: checkEmpty(titleModal),
        animation: checkEmpty(animation),

        searchText: checkEmpty(searchText),
    };
};
