import React, {FC, useEffect, useMemo, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import s from "./SearchForm.module.css"

import ItemOrder from "../ItemOrder";
import {AXI} from "../../libs/axi";
import {useNavigate} from "react-router-dom";
import clsx from "clsx";
import {useGetLocationData, useSetLocationData} from "../../hooks/locationData";
import TabsCat from "../TabsCat";
import Icon from "../Icon";
import {Item} from "../../types/types";


interface ItemCatigories {
    id: number;
    img: string;
    description: string | null;
    name: string;
}


interface ItemsNow {
    active?: number;
    isModal?: boolean;
    handleClickRes?: (id: number) => void;
    objectModal?: ObjectModal[];
}

interface ObjectModal {
    id: number;
    count: number;
}

export const SearchForm: FC<ItemsNow> = ({active = 1, handleClickRes, objectModal, isModal}) => {
    const [arrCat, setArrCat] = useState<ItemCatigories[]>([])
    const [arrCatEat, setArrCatEat] = useState<ItemCatigories[]>([])
    const [itemSearch, setItemSearch] = useState<Item[]>([])

    const setData = useSetLocationData();
    const [searchValue, setSearchValue] = useState("");
    const [modalSearch, setModalSearch] = useState("");
    const [isInputActive, setInputActive] = useState(false);
    const [isActiveDrink, setActiveDrink] = useState(1);
    const {modal} = useGetLocationData();
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    // useEffect(() => {
    //     fetch(`${AXI}/api/categories?id=2`)
    //         .then(res => res.json())
    //         .then((data: ItemCatigories[]) => {
    //
    //                 setArrCat(data);
    //             },
    //
    //             (error) => {
    //                 setIsLoaded(true);
    //                 setError(error);
    //             }
    //         )
    // }, [active])
    useEffect(() => {
        fetch(`${AXI}/api/categories?id=1`)
            .then(res => res.json())
            .then((data: ItemCatigories[]) => {

                    setArrCatEat(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )

    }, [])

    useEffect(() => {
        const appElement = document.getElementById('root');
        const appsf = document.getElementById('inputWrapper');


        if (appElement !== null) {
            if (!!modal) {
                if (appsf !== null) {
                    appsf.style.overflow = !isInputActive ? "" : 'hidden';
                    appsf.style.height = !isInputActive ? '' : '100vh';
                    if (modal != modalSearch) {

                        setModalSearch(modal)
                        setInputActive(false)
                        setSearchValue("")
                        setItemSearch([])
                    } else {
                        setInputActive(false)
                        setSearchValue("")
                        setItemSearch([])
                    }

                }

            } else {
                document.body.style.overflow = !isInputActive ? "" : 'hidden';
                // appElement.style.height = !isInputActive ? '' : '100vh';
            }


        } else {
            // Обработка случая, когда элемент не найден
            console.error("Элемент с идентификатором 'App' не найден.");
        }


    }, [isInputActive, modal])

    const handleInputClick = () => {
        setInputActive(true);
    };

    // Используйте useEffect для добавления слушателя событий для обработки кликов вне input
    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (inputRef.current && !(inputRef.current as Node).contains(event.target as Node)) {
                setInputActive(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        // Очищаем слушатель событий при размонтировании компонента
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (searchValue.trim() === "" || searchValue.length < 3) {
            return; // Если searchValue пустой, не делать POST запрос
        }

        const fetchData = async () => {
            try {
                const response = await fetch(`${AXI}/api/items/search`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: searchValue,
                        // category: 79, // Замените это значением из вашей логики
                        // maincategory: 2 // Замените это значением из вашей логики
                    })
                });

                if (response.ok) {
                    const data = await response.json();
                    setItemSearch(data)
                } else {
                    console.error('Ошибка при выполнении POST запроса');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Вызовите функцию fetchData при каждом изменении searchValue

    }, [searchValue]);

    const memoizedTabsCat = useMemo(() => (
        <TabsCat activeDrink={isActiveDrink} setActiveDrink={setActiveDrink} />
    ), [isActiveDrink]);
    const handleLinkClick = (id: number) => {
        // navigate(`/menupage?categories=${id}`);
        setData(["modalCategories",id])


        setInputActive(false)
    };

    return (
        <div className={s.inputWrapper}>

            {!isInputActive &&
            <div className={s.svgWrapper}>
                <Icon.Search/>
            </div>
            }
            <div className={s.inputItem}>


                <div className={s.inputSearch}>

                    {isInputActive &&
                    <div className={s.Arrow}
                         onClick={() => {
                             setInputActive(false)
                             setSearchValue("")
                             setItemSearch([])
                         }}>
                        <Icon.Arrow/>
                    </div>}
                    <input
                        name="search"
                        className={clsx(s.input, {
                            [s.activeInput]: isInputActive
                        })}
                        autoComplete={"off"}
                        placeholder="Поиск"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onClick={handleInputClick} // Добавьте обработчик клика на input
                    />

                    {searchValue && searchValue.length > 2 &&
                    <div className={s.svgIcon}
                         onClick={() => {
                             // setInputActive(false)
                             setSearchValue("")
                             setItemSearch([])
                         }}>
                        <Icon.Close/>
                    </div>}


                    <div className={s.RU}>
                        <Icon.RU/>
                    </div>

                </div>

                {isInputActive &&
                <div className={clsx(s.tooltip, {
                    [s.toltipactive]: isInputActive,
                    [s.toltipModal]: !!modal
                })}>
                    {/*<div className={s.toltipBack} />*/}
                    {searchValue.length > 2 ?
                        itemSearch.length > 0 ?
                            <div className={s.wrapperSearch}>
                                {itemSearch.length > 0 && itemSearch.map(({id, discountprocent, groupModifiers,name, img, price, discount,hot}) =>

                                    <ItemOrder
                                        key={`${id}${name}`}
                                        id={id}
                                        name={name}
                                        hot={hot}
                                        img={img}
                                        price={price}
                                        discount={discount}
                                        discountprocent={discountprocent}
                                        handleClickRes={handleClickRes}
                                        groupModifiers={groupModifiers}
                                        objectModal={objectModal}
                                    />
                                )}
                            </div>
                            :
                            <div className={s.noneSearch}>
                                <svg width="106" height="106" viewBox="0 0 106 106" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.25 4.3125H39.75C44.686 4.3125 48.6875 8.31395 48.6875 13.25V39.75C48.6875 44.686 44.686 48.6875 39.75 48.6875H13.25C8.31395 48.6875 4.3125 44.686 4.3125 39.75V13.25C4.3125 8.31395 8.31395 4.3125 13.25 4.3125Z"
                                        stroke="#C7C9D2" strokeWidth="2"/>
                                    <path
                                        d="M66.25 4.3125H92.75C97.686 4.3125 101.688 8.31395 101.688 13.25V39.75C101.688 44.686 97.686 48.6875 92.75 48.6875H66.25C61.314 48.6875 57.3125 44.686 57.3125 39.75V13.25C57.3125 8.31395 61.314 4.3125 66.25 4.3125Z"
                                        stroke="#C7C9D2" strokeWidth="2"/>
                                    <path
                                        d="M13.25 57.3125H39.75C44.686 57.3125 48.6875 61.314 48.6875 66.25V92.75C48.6875 97.686 44.686 101.688 39.75 101.688H13.25C8.31395 101.688 4.3125 97.686 4.3125 92.75V66.25C4.3125 61.314 8.31395 57.3125 13.25 57.3125Z"
                                        stroke="#C7C9D2" strokeWidth="2"/>
                                    <path
                                        d="M92.3525 87.668C95.2642 83.5818 96.5339 78.5507 95.9099 73.5722C95.2859 68.5938 92.8137 64.0316 88.9836 60.7905C85.1534 57.5495 80.2452 55.8661 75.2321 56.0744C70.219 56.2826 65.4673 58.3673 61.9192 61.9148C58.3711 65.4624 56.2858 70.2139 56.0768 75.2269C55.8679 80.24 57.5505 85.1485 60.791 88.9791C64.0316 92.8096 68.5934 95.2825 73.5717 95.9072C78.5501 96.5319 83.5814 95.2629 87.668 92.3518L97.0324 101.717C97.6572 102.32 98.4939 102.654 99.3624 102.647C100.231 102.639 101.062 102.291 101.676 101.677C102.29 101.062 102.638 100.232 102.646 99.3631C102.654 98.4945 102.32 97.6578 101.716 97.0331L92.3525 87.668ZM76.1875 89.4375C73.5669 89.4375 71.0052 88.6604 68.8262 87.2045C66.6473 85.7485 64.949 83.6792 63.9461 81.258C62.9432 78.8369 62.6808 76.1728 63.1921 73.6025C63.7034 71.0323 64.9653 68.6714 66.8183 66.8183C68.6714 64.9653 71.0323 63.7033 73.6026 63.1921C76.1728 62.6808 78.8369 62.9432 81.2581 63.9461C83.6792 64.9489 85.7486 66.6472 87.2045 68.8262C88.6604 71.0051 89.4375 73.5669 89.4375 76.1875C89.4328 79.7002 88.0353 83.0676 85.5514 85.5514C83.0676 88.0353 79.7002 89.4328 76.1875 89.4375Z"
                                        fill="#F85D42"/>
                                </svg>
                                <div> Ничего не нашлось</div>
                            </div>
                        :
                        <div className={s.catigoies}>
                            {/*<div>*/}
                            {/*    /!*<TabsCat activeDrink={isActiveDrink} setActiveDrink={setActiveDrink} />*!/*/}
                            {/*    {memoizedTabsCat}*/}
                            {/*</div>*/}
                            <div className={s.activeCat}>
                            {isActiveDrink === 1 ? <>

                                {arrCatEat.length > 0 && arrCatEat.map(({id, name, img}) =>
                                    <div onClick={() => handleLinkClick(id)} className={s.itemCat} key={`${id}${name}`}>
                                        <button className={s.menuButtonCategory}>
                                            {img && <img src={!img.includes('nophoto.png') ? `${img}`
                                                : `https://edabirja.ru/img/no-photos.png`}/>}
                                        </button>
                                        <div>{name}</div>
                                    </div>
                                )}
                                </>:  <>

                                {arrCat.length > 0 && arrCat.map(({id, name, img}) =>
                                    <div onClick={() => handleLinkClick(id)} className={s.itemCat} key={`${id}${name}`}>
                                        <button className={s.menuButtonCategory}>
                                            {img && <img src={!img.includes('nophoto.png') ? `${img}`
                                                : `https://edabirja.ru/img/no-photos.png`}/>}
                                        </button>
                                        <div>{name}</div>
                                    </div>
                                )}
                            </>
                            }
                            </div>


                        </div>
                    }
                </div>
                }
            </div>
        </div>
    )
}